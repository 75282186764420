<template>
  <div class="app-disease-type gb-box">
    <div class="box">
      <!-- <div class="title">病种分布</div> -->
      <div class="bd capsule-chart">
        <dv-capsule-chart :config="options" style="position: relative; height: 100%;" v-if="this.options.data.length"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "diseaseType",
    data() {
      return {
        options: {
          data: [
            // {
            //   name: '妇科',
            //   value: 167
            // },
            // {
            //   name: '脑科',
            //   value: 123
            // },
            // {
            //   name: '骨科',
            //   value: 98
            // },
            // {
            //   name: '外科',
            //   value: 75
            // },
            // {
            //   name: '内分泌',
            //   value: 66
            // },
          ],
          colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
          showValue: true
        }
      }
    },
    methods: {
      getData(data) {
        // console.log(222,data);
        let list = [];
        data.prescriptionDrugs = data.prescriptionDrugs.slice(0,10);
        if (data.prescriptionDrugs && data.prescriptionDrugs.length) {
          data.prescriptionDrugs.forEach(ele=>{
            list.push({name: ele.name, value:ele.num});
          })
          this.options.data = list;
        }
      }
    }
  }
</script>

<style scoped>
.gb-box .box .capsule-chart{
  padding-right: 20px;
}
</style>
