<template>
  <div class="app-container">
    <dv-full-screen-container>
      <div class="head">
        <div class="logo">
          <img src="~@/assets/logo.png" alt="" />
        </div>
        <dv-decoration-1
          class="decoration"
          :color="['#ffffff', 'transparent']"
        />
      </div>
      <div class="main">
        <div class="row">
          <div class="col digital">
            <div class="flop">
              <dv-border-box-13>
                <digital-flop ref="digitalFlop"/>
              </dv-border-box-13>
            </div>
            <div class="end-box">
              <dv-border-box-13>
                <hospital  ref="hospital"/>
              </dv-border-box-13>
            </div>
          </div>
          <div class="col center">
            <div class="map">
              <fly-map ref="flyMap"/>
            </div>
            <div class="end-box center-last">
              <div class="charts">
                <centerCase ref="centerCase"/>
              </div>
            </div>
          </div>
          <div class="col digital">
            <!--#病种分布-->
            <div class="part-sm">
              <dv-border-box-13>
                <disease-type ref="diseaseType"/>
              </dv-border-box-13>
            </div>
            <!--#饼状图PIE-->
            <div class="part-sm mar">
              <spread-panel ref="spreadPanel"/>
            </div>
            <!--#年龄分布-->
            <div class="end-box">
              <age-pie ref="agePie"/>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <span>黄山市生命科学研究院&nbsp;&nbsp;</span>
        <span>中安九方健康产业（安徽）有限公司 技术支持</span>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
import digitalFlop from "./digitalFlop";
import hospital from "./hospital";
import centerCase from "./centerCase";
import flyMap from "./flyMap";
import diseaseType from "./diseaseType";
import spreadPanel from "./spreadPanel";
import agePie from "./agePie";

export default {
  name: "index",
  components: {
    digitalFlop,
    hospital,
    flyMap,
    centerCase,
    diseaseType,
    spreadPanel,
    agePie,
  },
  data() {
    return {
      loading: false,
      doctorId: "",
      teamsId: "",
      allData: "",
    };
  },
  mounted() {
    if (localStorage.userInfo) {
      this.doctorId = JSON.parse(localStorage.userInfo).id;
    }
    if (localStorage.currentTeam) {
      this.teamsId = JSON.parse(localStorage.currentTeam).id;
    }
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/homePage/getJfyHome"),
        method: "post",
        data: {
          // stringParam1: this.teamsId,
          // year: this.year,
          // stringParam2: this.doctorId,
        },
      });
      if (res.status) {
        this.allData = res.data;
        this.$nextTick(()=>{
          this.$refs.digitalFlop.createData(res.data);
          this.$refs.flyMap.initEchartMap(res.data);
          this.$refs.centerCase.drawLine(res.data);
          this.$refs.agePie.drawLine(res.data);
          this.$refs.hospital.getData(res.data);
          this.$refs.diseaseType.getData(res.data);
          this.$refs.spreadPanel.getData(res.data);
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app-container {
  position: relative;
  background-color: #03050c;
  height: 100%;

  #dv-full-screen-container {
    // height: 100% !important;
    font-size: 0.08rem;
    background-image: url("~@/assets/bg.png");
    background-position: 0;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    .head {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.05rem 0.1rem;
      height: 10vh;

      .logo {
        position: relative;
        height: 100%;

        img {
          max-height: 100%;
        }
      }

      .decoration {
        width: 300px;
        height: 50px;
      }
    }

    .main {
      flex: 1;
      padding: 0 0.1rem;

      .row {
        position: relative;
        height: 100%;
        margin: 0 -1vw;
        display: flex;

        .col {
          padding: 1vw;
          height: 100%;
          display: flex;
          flex-direction: column;

          &.digital {
            flex-basis: 30%;
            flex-shrink: 0;
          }

          &.center {
            flex: 1;
          }

          .flop {
            width: 100%;
            height: 40%;
          }

          .part-sm {
            width: 100%;
            height: 30%;

            &.mar {
              margin-top: 0.1rem;
            }
          }

          .map {
            width: 100%;
            height: 80%;
          }

          .end-box {
            flex: 1;
            padding-top: 0.1rem;

            &.center-last {
              margin-top: -15%;
              padding: 0.1rem;
              display: flex;
              flex-direction: column;

              .title {
                width: 100%;
                font-size: 0.09rem;
                color: #ffffff;
                font-weight: 600;
              }

              .charts {
                flex: 1;
                width: 100%;
              }
            }
          }
        }
      }
    }

    .foot {
      padding: 0.1rem 0.1rem 0.05rem 0.1rem;
      flex-shrink: 0;
      font-size: 0.07rem;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
    }
  }
}
</style>
