<template>
  <div class="app-spread-panel">
    <div class="row">
      <div class="col">
        <div class="box">
          <div class="title">成分分布</div>
          <div class="pie" id="ingredient"></div>
        </div>
      </div>
      <div class="col">
        <div class="box">
          <div class="title">中成药病种</div>
          <div
            class="pie"
            id="t-medicine"
            v-if="
              medOpts.series &&
              medOpts.series.length &&
              medOpts.series[0].data.length
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "spreadPanel",
  data() {
    return {
      ingOpts: {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}",
        },
        series: [
          {
            type: "pie",
            radius: ["0", "100%"],
            roseType: "rose",
            itemStyle: {
              borderRadius: 8,
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              show: true,
              position: "inside",
              formatter: "{b}: {d}%",
              color: "#ffffff",
            },
            labelLine: {
              show: false,
            },
            data: [
              // { value: 1017, name: "中药" },
              // { value: 583, name: "西药" },
            ],
          },
        ],
      },
      medOpts: {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c}",
        },
        series: [
          {
            type: "pie",
            radius: ["0", "100%"],
            itemStyle: {
              borderRadius: 8,
              borderColor: "#fff",
              borderWidth: 1,
            },
            label: {
              show: true,
              position: "inside",
              formatter: "{b}: {d}%",
              color: "#ffffff",
            },
            labelLine: {
              show: false,
            },
            data: [
              // { value: 30, name: '妇科', itemStyle: {color:'#FFDB5C'} },
              // { value: 70, name: '脑病', itemStyle: {color:'#37A2DA'} },
              // { value: 10, name: '骨科', itemStyle: {color:'#9FE6B8'} },
              // { value: 40, name: '外科', itemStyle: {color:'#67E0E3'} },
              // { value: 15, name: '内分泌', itemStyle: {color:'#32C5E9'} },
            ],
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    bootStrap() {
      const the = this;
      const ingChart = the.$echarts.init(document.getElementById("ingredient"));
      const medChart = the.$echarts.init(document.getElementById("t-medicine"));
      ingChart.setOption(the.ingOpts);
      medChart.setOption(the.medOpts);
    },
    getData(data) {
      console.log(222, data);
      let list = [];
      let list2 = [];
      if (data.groupPrescriptionDrugs && data.groupPrescriptionDrugs.length) {
        data.groupPrescriptionDrugs.forEach(ele=>{
          let obj = {
            value: ele.num
          };
          if (ele.prescriptionType == 1) {
            obj.name = '中药';
          }
          if (ele.prescriptionType == 2) {
            obj.name = '西药药';
          }
          list2.push(obj)
        })
        this.ingOpts.series[0].data = list2;
      }
      if (data.prescriptionDrugs && data.prescriptionDrugs.length) {
        data.prescriptionDrugs.forEach((ele) => {
          list.push({ name: ele.name, value: ele.num });
        });
        this.medOpts.series[0].data = list;
      }
      this.$nextTick(function () {
        this.bootStrap();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.app-spread-panel {
  position: relative;
  height: 100%;

  .row {
    position: relative;
    height: 100%;
    display: flex;
    margin: 0 -0.05rem;

    .col {
      flex-basis: 50%;
      padding: 0 0.05rem;

      .box {
        background-color: rgba(6, 30, 93, 0.5);
        border-top: 2px solid rgba(1, 153, 209, 0.5);
        position: relative;
        height: 100%;
        padding: 0.1rem;
        display: flex;
        flex-direction: column;

        .title {
          font-size: 0.09rem;
          color: #ffffff;
          font-weight: 600;
        }

        .pie {
          flex: 1;
          padding-top: 0.1rem;
        }
      }
    }
  }
}
</style>
